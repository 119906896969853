/*#region Basic*/
* { padding: 0; margin: 0;}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0px;
    padding: 0px;
    top: 0;
    left: 0;
    color: rgb(240, 240, 240);
    background-color: rgb(30, 30, 30);
    font-family: Consolas, monaco, monospace;
}

h1 {
    font-weight: normal;
    font-size: 50px;
    transition: 0.5s;
}
  
h2 {
    font-weight: normal;
    font-size: 40px;
    transition: 0.5s;
}
  
h3 {
    font-weight: normal;
    font-size: 30px;
    transition: 0.5s;
}
  
h4 {
    font-weight: normal;
    font-size: 26px;
    transition: 0.5s;
}
 
p {
    font-weight: normal;
    font-size: 22px;
    transition: 0.5s;
}

strong {
    font-weight: bold;
    color: #0cb8e0;
}

a {
    color: #0cb8e0; 
}

.weak {
    color: #8c8c8c;
}

.center {
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}

.textCenter {
    text-align: center;
}

.margin {
    margin: 30px 20px 30px 20px;
}
/*#endregion */

/*#region Independent*/
    /*#region Intro*/
    .bg {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        min-height: 700px;
        background-color: rgb(20, 20, 20);
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.8);
    }
    
    .bgImg {
        position: absolute;
        width: 100vw;
        height: 100vh;
        min-height: 700px;
    }
    
    .intro {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        align-content: center;
        z-index: 1;
    }
    
    .introBox {
        position: absolute;
        width: 1000px;
        height: 600px;
        transition: 0.5s;
        background-color: rgb(35, 35, 35);
        box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.9);
    }
    /*#endregion */

    /*#region UP*/
    .up {
        position: fixed;
        right: 50px;
        bottom: 50px;
        width: 80px;
        height: 80px;
        opacity: 0.7;
        transition: 0.5s;
        border-radius: 100%;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.8);
        z-index: 10;
    }
    
    .up:hover {
        opacity: 1;
    }
    
    .upIcon {
        width: 80%;
        height: 80%;
        margin: 10%;
    }
    /*#endregion */

    /*#region Tab*/
    .tabBox {
        width: 100%;
        height: 70px;
        margin-bottom: 0 !important;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        background-color: rgb(25, 25, 25);
    }

    .tabItem {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        margin: 10px;
        padding: 8px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 20px;
        text-align: center;
        border-radius: 40px;
        transition: 0.5s;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.8);
    }
    
    .tabItem:hover {
        padding-left: 30px;
        padding-right: 30px;
        background-color: rgb(45, 45, 45) !important;
    }

    .tabItem.enable {
        padding-left: 40px;
        padding-right: 40px;
        background: linear-gradient(45deg, #28f29c, #0cb8e0);
    }

    .tabItem.disable {
        background-color: rgb(35, 35, 35);
    }
    /*#endregion */

    /*#region Email*/
    .emailBox {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .emailInput {
        width: calc(100% - 46px);
        height: auto;
        margin-top: 30px;
        padding: 20px;
        border: 3px solid #202020;
        outline: none;
        font-size: 20px;
        color: rgb(240, 240, 240);
        background-color: #303030;
        border-radius: 30px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.8);
    }
    
    .emailInput:focus {
        border: 3px solid #0cb8e0;
    }
    
    .emailText {
        width: calc(100% - 46px);
        height: 300px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px;
        border: 3px solid #202020;
        outline: none;
        font-size: 20px;
        color: rgb(240, 240, 240);
        background-color: #303030;
        border-radius: 30px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.8);
        font-family: Consolas, monaco, monospace;
    }
    
    .emailText:focus {
        border: 3px solid #0cb8e0;
    }
    
    .emailBtn {
        width: 30%;
        height: auto;
        padding: 15px;
        text-align: center;
        font-size: 20px;
        border-radius: 30px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.8);
        transition: 0.5s;
    }
    
    .emailBtn:hover {
        padding: 20px;
    }
    /*#endregion */

    /*#region Footer*/
    .footer {
        width: 100%;
        height: auto;
        padding-bottom: 20px;
        background-color: rgb(20, 20, 20);
        transition: 0.5s;
    }
    
    .logo {
        display: block;
        width: 200px;
        height: auto;
        padding-top: 30px;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        transition: 0.5s;
    }
    
    .snsBox { 
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
    
    .sns {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        margin: 10px;
        background: white;
        border-radius: 22px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8);
    } 
    
    .copyright {
        font-size: 30px;
        text-align: center;
        transition: 0.5s;
    }
    /*#endregion */
/*#endregion */

/*#region Control*/
    /*#region Page*/
    .page {
        width: 90%;
        max-width: 1000px;
        margin-top: 100px;
        margin-bottom: 100px;
        margin-left: auto;
        margin-right: auto;
        transition: 0.5s;
    }
    
    .pageBody {
        width: 90%;
        margin-top: 70px;
        margin-bottom: 70px;
        margin-left: auto;
        margin-right: auto;
        transition: 0.5s;
    }
    /*#endregion */

    /*#region Popup */
    .popupBox {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: 0.2s;
        backdrop-filter: blur(4px);
        background-color: rgba(20, 20, 20, 0.05);
        z-index: 10;
    }

    .popupMain {
        position: fixed;
        width: 50%;
        height: 80%;
        top:50%;
        left:50%;
        opacity: 0;
        border-radius: 30px;
        transition: 0.2s;
        transform: translate(-50%, -50%);
        background-color: rgb(40, 40, 40);
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.8);
        z-index: 15;
    }

    .popupClose {
        position: absolute;
        top: 0;    
        right: 0;
        width: 60px;
        height: 60px;
        margin: 10px;
        transition: 0.5s;
    }

    .popupClose:hover {
        width: 70px;
        height: 70px;
    }

    .popupContent {
        margin-top: 20px;
        margin-left: 20px;
    }
    /*#endregion */

    /*#region Tag */
    .tag {
        display: inline-block;
        position: relative;
        padding: 8px 25px 8px 25px;
        margin: 5px;
        font-size: 20px;
        transition: 0.5s;
        border: none;
        opacity: 0.8;
        border-radius: 20px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.6);
    }

    .tag:hover {
        opacity: 1;
    }

    .tag:focus {
        opacity: 1;
        outline: none;
        padding: 10px 30px 10px 30px;
        border: 3px rgb(240, 240, 240) solid;
    }

    .tag.t {
        opacity: 1;
    }

    .type {
        display: inline-block;
        font-size: 20px;
        margin-left: 15px;
    }
    /*#endregion */

    /*#region Description */
    .description {
        width: 90%;
        height: auto;
        padding: 3%;
        margin-top: 30px;
        border: #303030 solid 4px;
        border-radius: 20px;
        background-color: #404040;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8);
    }

    .des {
        border-top: #303030 solid 3px;
        margin-top: 10px;
        margin-bottom: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
    }
    /*#endregion */

    /*#region TeamActivities*/
    .teamEx {
        display: flex;
        justify-content: space-between;
    }
    /*#endregion */

/*#endregion */

/*#region Contents*/
    /*#region AboutMe */
    .bgBox {
        height: 80vh;
    }
    
    .bgCover {
        position:absolute;
        display: block;
        width: 100vw;
        height: 80vh;
        left: 0;
        overflow: hidden;
        object-fit: cover;
        background-color: rgb(20, 20, 20);
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.8);
    }
    
    .bgVid {
        min-width: 100%;
        min-height: 100%;
        opacity: 0.8;
        transition: 0.5s;
    }
    
    .bgVid:hover{
        opacity: 1;
    }
    /*#endregion */ 

    /*#region Project */
    .projectGrid {
        margin-top: 20px;
        margin-bottom: 100px;
        display: grid;
        transition: 0.5s;
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    .projectItem {
        width: 94%;
        height: auto;
        margin: 3%;
        margin-top: 30px;
        margin-bottom: auto;
        padding-bottom: 10px;
        transition: 0.5s;
        border-radius: 22px;
        background-color: rgb(40, 40, 40);
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.8);
    }
    
    .projectItem:hover {
        width: 96%;
        margin: 2%;
        margin-top: 30px;
        margin-bottom: auto;
        background-color: rgb(44, 44, 44);
    }

    .projectItemNoC:hover {
        background-color: rgb(40, 40, 40) !important;
    }
    
    .thumbnail {
        width: 100%;
        height: 42%;
        overflow: hidden;
        object-fit: cover;
        transition: 0.5s;
        border-radius: 22px 22px 0px 0px;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.8);
    }

    .artThumbnail {
        width: 80%;
        height: 80%;
        margin:10%;
        overflow: hidden;
        object-fit: cover;
        transition: 0.5s;
        border-radius: 22px 22px 22px 22px;
    }
    
    .projectTitle {
        margin-top: 10px;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        line-height: 25px;
    }
    
    .projectDescription {
        width: auto;
        height: auto;
        padding: 10px;
        font-size: 20px;
    }

    .stl {
        width: 100%;
        height: auto;
        border-radius: 22px;
        margin-left: auto;
        margin-right: auto;
    }

    .ytbox {
        position: relative;
        width: 100%;
    }
     
    .ytbox:before {
        content: "";
        display: block;
        padding-top: 56.25%;
    }
     
    .ytcontent {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    /*#endregion */ 

    /*#region Certifications*/
    .certifications {
        width: 98%;
        height: auto;
        margin-left: 1%;
        margin-right: 1%;
        border-radius: 20px;
    }
    /*#endregion */ 
/*#endregion */ 

/*#region Colors */
.color0 {
    background: linear-gradient(135deg, #28f29c, #0cb8e0);
}

.color1 {
    background: linear-gradient(135deg, #ea4d2c, #ff881b);
}

.color2 {
    background: linear-gradient(135deg, #ffa62e, #ffcf1b);
}

.color3 {
    background: linear-gradient(135deg, #a7e05f, #28f29c);
}

.color4 {
    background: linear-gradient(135deg, #12ab97, #64e8de);
}

.color5 {
    background: linear-gradient(135deg, #0cb8e0, #8a64eb);
}

.color6 {
    background: linear-gradient(135deg, #8441a4, #f650a0);
}

.color7 {
    background: linear-gradient(135deg, #ff5b94, #ff9897);
}

.color8 {
    background: linear-gradient(135deg, #f858a2, #fe585c);
}

.color9 {
    background: linear-gradient(135deg, #4ADEDE, #1AA7EC);
}

.color10 {
    background: linear-gradient(135deg, #86E3CE, #D0E6A5);
}

.color11 {
    background: linear-gradient(135deg, #FC887B, #FFDD95);
}

.color12 {
    background: linear-gradient(135deg, #09FE99, #0175FC);
}

.color13 {
    background: linear-gradient(135deg, #ED0889, #FF7068);
}

.color14 {
    background: linear-gradient(135deg, #FAB43A, #EA0A86);
}
/*#endregion */ 

@media (max-width: 1000px) {
    h1 {
        font-weight: normal;
        font-size: 38px;
    }
      
    h2 {
        font-weight: normal;
        font-size: 32px;
    }
      
    h3 {
        font-weight: normal;
        font-size: 28px;
    }
      
    h4 {
        font-weight: normal;
        font-size: 24px;
    }
     
    p {
        font-weight: normal;
        font-size: 20px;
    }
    
    strong {
        font-weight: bold;
        color: #0cb8e0;
    }

    .introBox {
        width: 90vw;
        height: auto;
    }

    .up {
        position: fixed;
        right: 20px;
        bottom: 20px;
        width: 50px;
        height: 50px;
    }
    
    .tabBox {
        height: 60px;
    }
    
    .tabItem {
        height: 22px;
        line-height: 22px;
        font-size: 14px;
    }
    
    .emailBox {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .logo {
        width: 120px;
    }

    .copyright{
        padding: 10px;
        font-size: 20px;
    }

    .page {
        width: 95%;
        max-width: none;
    }
    
    .projectGrid {
        grid-template-columns: 1fr 1fr;
    }

    .teamEx {
        display: block;
        justify-content: baseline;
    }
}

@media (max-width: 550px) {
    .projectGrid {
        grid-template-columns: 1fr;
    }
}